export default {
    // private
    dashboard: "/dashboard",
    post: "/post",
    addpost: "/post/add",
    editpost: "/post/edit/",
    addproduct: "/product/add",
    editproduct: "/product/edit/",
    page: "/page",
    product: "/product",
    category: "/category",
    addcategory: "/category/add",
    editcategory: "/category/edit/",
    media: "/media",
    user: "/user",
    adduser: "/user/add",
    edituser: "/user/edit/",
    role: "/role",
    setting: "/setting",
    // auth
    login: "/login",
};
