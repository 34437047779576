import React, { useContext } from "react";
import { string } from "prop-types";
import { Col, Row } from "react-bootstrap";
import { BiArrowBack, BiPlus } from "react-icons/all";
import { withRouter, Link } from "react-router-dom";
import { SidebarContext } from "../../hooks/useSidebar";
import { createUseStyles, useTheme } from "react-jss";
import { removeUserSession, getUser } from "../../resources/auth";
import {
    getRandomColor,
    createImageFromInitials,
} from "../../resources/utilities";
import SLUGS from "../../resources/slugs";
import DropdownComponent from "../dropdown/DropdownComponent";

const useStyles = createUseStyles((theme) => ({
    avatar: {
        height: 35,
        width: 35,
        borderRadius: 50,
        marginLeft: 14,
        border: "1px solid #DFE0EB",
    },
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: 40,
    },
    cursorPointer: {
        cursor: "pointer",
    },
    name: {
        fontFamily: "Muli",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "20px",
        textAlign: "right",
        letterSpacing: 0.2,
        "@media (max-width: 768px)": {
            display: "none",
        },
    },
    separator: {
        borderLeft: "1px solid #DFE0EB",
        marginLeft: 32,
        marginRight: 32,
        height: 32,
        width: 2,
        "@media (max-width: 768px)": {
            marginLeft: 12,
            marginRight: 12,
        },
    },
    title: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        color: theme.color.primaryColor,
        fontWeight: 700,
        fontSize: 24,
        lineHeight: "30px",
        letterSpacing: 0.3,
        "@media (max-width: 1080px)": {
            marginLeft: 50,
        },
        "@media (max-width: 468px)": {
            fontSize: 20,
        },
    },
    iconStyles: {
        color: theme.color.primaryColor,
        cursor: "pointer",
        marginLeft: 25,
        "@media (max-width: 768px)": {
            marginLeft: 12,
        },
    },
    center: {
        display: "flex",
        alignItems: "center",
    },
    right: {
        justifyContent: "flex-end",
    },
    addButton: {
        backgroundColor: theme.color.hoverColor,
        verticalAlign: "middle",
        margin: "0px 20px",
        alignItems: "center",
        display: "inlince-block",
        border: "none",
        fontWeight: "bold",
        fontSize: "24px !important",
        "&:hover": {
            color: theme.color.hoverColor,
            border: "1px solid",
            borderColor: theme.color.hoverColor,
            backgroundColor: theme.color.bgcolor,
        },
    },
    backButton: {
        backgroundColor: "transparent",
        verticalAlign: "middle",
        margin: "0px",
        paddingLeft: "0px",
        alignItems: "center",
        display: "inlince-block",
        border: "none",
        fontWeight: "bold",
        fontSize: "24px !important",
        color: theme.color.hoverColor,
        "&:hover": {
            color: "#fff",
            border: "0px solid",
            borderColor: "#fff",
            backgroundColor: theme.color.bgcolor,
        },
    },
}));

function HeaderComponent(props) {
    const user = getUser();
    const { currentItem } = useContext(SidebarContext);
    const theme = useTheme();
    const classes = useStyles({ theme });
    let addButton;
    let backButton;
    let title;
    let imgSrc = "";

    const handleLogout = () => {
        removeUserSession();
        props.history.push("/login");
    };

    if (currentItem === SLUGS.dashboard) {
        title = "Dashboard";
    } else if (currentItem === SLUGS.post) {
        title = "Post";
        addButton = (
            <Link to={SLUGS.addpost} className={"btn btn-primary " + classes.addButton}>
                <BiPlus />
            </Link>
        );
    } else if (currentItem === SLUGS.addpost) {
        title = "Add Post";
        backButton = (
            <Link to={SLUGS.post} className={"btn btn-primary " + classes.backButton}>
                <BiArrowBack />
            </Link>
        );
    } else if (currentItem.substring(0, 11) === SLUGS.editpost) {
        title = "Edit Post";
        backButton = (
            <Link to={SLUGS.post} className={"btn btn-primary " + classes.backButton}>
                <BiArrowBack />
            </Link>
        );
    } else if (currentItem === SLUGS.product) {
        title = "Product";
        addButton = (
            <Link to={SLUGS.addproduct} className={"btn btn-primary " + classes.addButton}>
                <BiPlus />
            </Link>
        );
    } else if (currentItem === SLUGS.addproduct) {
        title = "Add Product";
        backButton = (
            <Link to={SLUGS.product} className={"btn btn-primary " + classes.backButton}>
                <BiArrowBack />
            </Link>
        );
    } else if (currentItem.substring(0, 14) === SLUGS.editproduct) {
        title = "Edit Product";
        backButton = (
            <Link to={SLUGS.product} className={"btn btn-primary " + classes.backButton}>
                <BiArrowBack />
            </Link>
        );
    } else if (currentItem === SLUGS.category) {
        title = "Category";
        addButton = (
            <Link to={SLUGS.addcategory} className={"btn btn-primary " + classes.addButton}>
                <BiPlus />
            </Link>
        );
    } else if (currentItem === SLUGS.addcategory) {
        title = "Add Category";
        backButton = (
            <Link to={SLUGS.category} className={"btn btn-primary " + classes.backButton}>
                <BiArrowBack />
            </Link>
        );
    } else if (currentItem.substring(0, 15) === SLUGS.editcategory) {
        title = "Edit Category";
        backButton = (
            <Link to={SLUGS.category} className={"btn btn-primary " + classes.backButton}>
                <BiArrowBack />
            </Link>
        );
    } else if (currentItem === SLUGS.media) {
        title = "Media";
    } else if (currentItem === SLUGS.page) {
        title = "Page";
        addButton = (
            <Link to={SLUGS.addpost} className={"btn btn-primary " + classes.addButton}>
                <BiPlus />
            </Link>
        );
    } else if (currentItem === SLUGS.user) {
        title = "User";
        addButton = (
            <Link to={SLUGS.adduser} className={"btn btn-primary " + classes.addButton}>
                <BiPlus />
            </Link>
        );
    } else if (currentItem === SLUGS.adduser) {
        title = "Add User";
        backButton = (
            <Link to={SLUGS.user} className={"btn btn-primary " + classes.backButton}>
                <BiArrowBack />
            </Link>
        );
    } else if (currentItem.substring(0, 11) === SLUGS.edituser) {
        title = "Edit User";
        backButton = (
            <Link to={SLUGS.user} className={"btn btn-primary " + classes.backButton}>
                <BiArrowBack />
            </Link>
        );
    } else if (currentItem === SLUGS.setting) {
        title = "Setting";
    } else {
        title = "";
    }
    return (
        <Row className={classes.container}>
            <Col xs={'8'} className={classes.center}>
                {backButton}
                <span className={classes.title}>{title}</span>
                {addButton}
            </Col>
            <Col xs={'4'} className={[classes.center, classes.right].join(" ")}>
                <div className={classes.separator}></div>
                <DropdownComponent
                    position="right"
                    label={
                        <>
                            <span
                                className={[
                                    classes.name,
                                    classes.cursorPointer,
                                ].join(" ")}
                            >
                                {user}
                            </span>
                            <img
                                className={[
                                    classes.avatar,
                                    classes.cursorPointer,
                                ].join(" ")}
                                id="preview"
                                src={
                                    imgSrc.length <= 0
                                        ? createImageFromInitials(
                                              500,
                                              user,
                                              getRandomColor()
                                          )
                                        : imgSrc
                                }
                                alt="profile-pic"
                            />
                        </>
                    }
                    options={[
                        {
                            label: "Logout",
                            onClick: () => handleLogout(),
                        },
                    ]}
                />
            </Col>
        </Row>
    );
}

HeaderComponent.propTypes = {
    title: string,
};

export default withRouter(HeaderComponent);
