import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SLUGS from "../resources/slugs";
import LoadingComponent from "../components/loading/LoadingComponent";

const DashboardComponent = lazy(() => import("./dashboard"));
const PostComponent = lazy(() => import("./post"));
const UserComponent = lazy(() => import("./user"));
const AddUserComponent = lazy(() => import("./user/form/AddComponent"));
const EditUserComponent = lazy(() => import("./user/form/EditComponent"));
const AddPostComponent = lazy(() => import("./post/form/AddComponent"));
const EditPostComponent = lazy(() => import("./post/form/EditComponent"));
const ProductComponent = lazy(() => import("./product/ProductComponent"));
const AddProductComponent = lazy(() => import("./product/form/AddComponent"));
const EditProductComponent = lazy(() => import("./product/form/EditComponent"));
const CategoryComponent = lazy(() => import("./category/CategoryComponent"));
const AddCategoryComponent = lazy(() => import("./category/form/AddComponent"));
const EditCategoryComponent = lazy(() =>import("./category/form/EditComponent"));
const SettingComponent = lazy(() =>import("./setting/SettingComponent"));


function PrivateRoutes() {
    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Switch>
                <Route
                    exact
                    path={SLUGS.dashboard}
                    component={DashboardComponent}
                />
                <Route exact path={SLUGS.post} component={PostComponent} />
                <Route
                    exact
                    path={SLUGS.addpost}
                    component={AddPostComponent}
                />
                <Route
                    exact
                    path={SLUGS.editpost + ":id"}
                    component={EditPostComponent}
                />
                <Route
                    exact
                    path={SLUGS.media}
                    render={() => <div>overviewTwo</div>}
                />
                <Route
                    exact
                    path={SLUGS.page}
                    render={() => <div>overviewTwo</div>}
                />
                <Route exact path={SLUGS.user} component={UserComponent} />
                <Route
                    exact
                    path={SLUGS.adduser}
                    component={AddUserComponent}
                />
                <Route
                    exact
                    path={SLUGS.edituser + ":id"}
                    component={EditUserComponent}
                />
                <Route
                    exact
                    path={SLUGS.product}
                    component={ProductComponent}
                />
                <Route
                    exact
                    path={SLUGS.addproduct}
                    component={AddProductComponent}
                />
                <Route
                    exact
                    path={SLUGS.editproduct + ":id"}
                    component={EditProductComponent}
                />
                <Route
                    exact
                    path={SLUGS.category}
                    component={CategoryComponent}
                />
                <Route
                    exact
                    path={SLUGS.addcategory}
                    component={AddCategoryComponent}
                />
                <Route
                    exact
                    path={SLUGS.editcategory + ":id"}
                    component={EditCategoryComponent}
                />
                <Route
                    exact
                    path={SLUGS.setting}
                    component={SettingComponent}
                />
                <Redirect to={SLUGS.dashboard} />
            </Switch>
        </Suspense>
    );
}

export default PrivateRoutes;
